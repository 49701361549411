import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Typography as T, Button, Box, Chip, CircularProgress, makeStyles } from "@material-ui/core";
import { Layout } from "../../components/Layout";
import MaterialTable from "../../components/MaterialTable/MaterialTable";
import { CommonModal } from "../../components/Modals/CommonModal";
import { useStores } from "../../stores";
import { NewsModal } from "./NewsModal";
import { getPrettyDate } from "../../utils/i18nUtils";
import { faClock, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../styles/colors";
import { ActionTableCell } from "../../components/ActionTableCell/ActionTableCell";
import { NewsTemplateListModal } from "./NewsTemplateListModal";
import { NewsFilters } from "../../components/NewsFilters/NewsFilters";
import { Form, FormSpy } from "react-final-form";
import moment from "moment";
import { TranslationFlags } from "../../components/TranslationFlags/TranslationFlags";

export interface NewsScreenProps {}

const useStyles = makeStyles({
  filterForm: {
    width: "100%",
  },
});

export const NewsScreen: FC<NewsScreenProps> = observer(() => {
  const PAGE_SIZE = 10;
  const {
    newsStore: {
      isLoading: isNewsLoading,
      news,
      singleNews,
      getNews,
      getSingleNews,
      deleteNews,
      newsTotal,
      setNewsFromTemplate,
      resetNews,
    },
    yhDataStore: { getEstates, getOwners, getRegions, estates, owners, regions, isLoading: isYhDataLoading },
  } = useStores();

  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const [deleteNewsModalOpen, setDeleteNewsModalOpen] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const classNames = useStyles();

  const fetchNews = useCallback(
    async (pageIndex?: number, filters?: { owner: string; region: string; estate: string }) => {
      const page = pageIndex === undefined ? currentPage : pageIndex;
      const skip = PAGE_SIZE * page;
      return getNews({
        take: PAGE_SIZE,
        skip,
        region: filters?.region,
        estate: filters?.estate,
        owner: filters?.owner,
      });
    },
    [getNews, currentPage]
  );

  useEffect(() => {
    if (!initialized) {
      getEstates();
      getOwners();
      getRegions();
      fetchNews();
      setInitialized(true);
    }
  }, [fetchNews, initialized, setInitialized, getOwners, getEstates, getRegions]);

  const onDeleteClick = useCallback(
    () => (id: string) => {
      getSingleNews(id);
      setDeleteNewsModalOpen(true);
    },
    [getSingleNews, setDeleteNewsModalOpen]
  );

  const onEditClick = useCallback(
    () => async (id: string) => {
      await getSingleNews(id);
      setNewsModalOpen(true);
    },
    [getSingleNews, setNewsModalOpen]
  );

  const closeModals = () => {
    setNewsModalOpen(false);
    setTemplateModalOpen(false);
    setDeleteNewsModalOpen(false);
  };

  const handleDeleteConfirm = async () => {
    if (singleNews?.id) {
      await deleteNews(singleNews.id);
    }
    closeModals();
    fetchNews();
  };

  const onCancelClick = () => {
    closeModals();
  };

  const handleAddFromTemplateClick = () => {
    setTemplateModalOpen(true);
  };

  const headerButtons = (
    <Box display="flex">
      <Box mr={2}>
        <Button
          id="addNewsButton"
          variant="outlined"
          startIcon="+"
          onClick={() => {
            resetNews();
            setNewsModalOpen(true);
          }}
        >
          {t("news.addNewNews")}
        </Button>
      </Box>
      <Button variant="outlined" startIcon="+" onClick={handleAddFromTemplateClick}>
        {t("news.addFromTemplate")}
      </Button>
    </Box>
  );

  const onPageChange = async (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
      fetchNews(pageIndex);
    }
  };

  const renderCellList = (arrString?: string, idAccessor?: string, valueObjects?: any[]) => {
    if (!valueObjects || !arrString || !idAccessor) return null;
    const items = arrString?.split(",") || [];
    if (items?.length > 3) {
      return t("news.many");
    }
    return (
      <Box>
        {items?.map((itemId: string, index: number) => {
          let itemName = valueObjects?.find((valueObj) => String(valueObj[idAccessor]) === itemId)?.name;
          return (
            <Box p="2px">
              <Chip size="small" label={itemName} />
            </Box>
          );
        })}
      </Box>
    );
  };

  const COLUMNS = useMemo(() => {
    const cols = [
      {
        accessor: "titlex",
        Header: "",
        Cell: ({ row: { original } }: { row: { original: INews } }) => {
          return (
            <Box display="flex" alignItems="center">
              {original.isDraft && (
                <Box mr={1}>
                  <Chip size="small" label={t("news.draft")} />
                </Box>
              )}
              {original.visibleFrom && original.visibleTo && moment().isAfter(original.visibleTo) && (
                <Box mr={1}>
                  <Chip size="small" label={t("news.inPast")} />
                </Box>
              )}
              {original.visibleFrom && original.visibleTo && moment().isBefore(original.visibleFrom) && (
                <Box mr={1}>
                  <Chip size="small" label={t("news.upcoming")} />
                </Box>
              )}
              {original.isExceptionNotice && (
                <Box mr={1}>
                  <FontAwesomeIcon color={colors.red} icon={faExclamationCircle} />
                </Box>
              )}
              {original.visibleFrom && original.visibleTo && (
                <Box>
                  <FontAwesomeIcon color={colors.red} icon={faClock} />
                </Box>
              )}
            </Box>
          );
        },
      },
      {
        accessor: "title",
        Header: t("common.title"),
        width: "25%",
        Cell: ({ row: { original } }: { row: { original: INews } }) => {
          return <Box>{original.title}</Box>;
        },
      },
      {
        accessor: "updatedAt",
        width: "15%",
        Header: t("common.updatedAt"),
        Cell: ({ row: { original } }: { row: { original: INews } }) => {
          return getPrettyDate(original.updatedAt);
        },
      },
      {
        accessor: "owners",
        width: "10%",
        Header: t("news.owner"),
        Cell: ({ row: { original } }: { row: { original: INews } }) =>
          renderCellList(original?.owner, "owner_id", owners),
      },
      {
        accessor: "regions",
        width: "10%",
        Header: t("news.region"),
        Cell: ({ row: { original } }: { row: { original: INews } }) =>
          renderCellList(original?.region, "region_id", regions),
      },
      {
        accessor: "estates",
        Header: t("news.estate"),
        width: "10%",
        Cell: ({ row: { original } }: { row: { original: INews } }) =>
          renderCellList(original?.estate, "estate_id", estates),
      },
      {
        accessor: "title_fi",
        Header: "",
        Cell: ({ row: { original } }: { row: { original: INews } }) => {
          const hasEnTranslation = !!original.title_en && !!original.content_en;
          const hasSvTranslation = !!original.title_sv && !!original.content_sv;

          return <TranslationFlags hasEnTranslation={hasEnTranslation} hasSvTranslation={hasSvTranslation} />;
        },
      },
      {
        accessor: "id",
        Header: "",
        Cell: ({ cell }: any) => (
          <ActionTableCell onEditClick={onEditClick} onDeleteClick={onDeleteClick} params={cell.value} />
        ),
      },
    ];
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEditClick, onDeleteClick, t, estates, owners, regions]);

  const renderDeleteNewsModal = () => (
    <CommonModal
      open={deleteNewsModalOpen}
      type="prompt"
      title={t("news.deleteNewsConfirm")}
      confirmText={t("common.remove")}
      onConfirm={handleDeleteConfirm}
      onCancel={onCancelClick}
    >
      <T>{t("news.deleteNewsConfirmBody", { title: singleNews?.title })}</T>
    </CommonModal>
  );

  const handleCreateFromTemplate = (template: INewsTemplate) => {
    setNewsFromTemplate(template);
    setNewsModalOpen(true);
  };

  // Not in use but required by Final Form
  const onFilterSubmit = () => {};

  const onFormChange = ({ values }: any) => {
    fetchNews(0, values);
  };

  return (
    <Layout headerText={t("sidemenu.news")} headerElementsRight={headerButtons}>
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
          <T color="textSecondary">{t("news.newsCount", { count: newsTotal })}</T>

          <Box minWidth="50%" maxWidth="50%" display="flex">
            <Form
              onSubmit={onFilterSubmit}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit} className={classNames.filterForm}>
                    <NewsFilters selectSingle />
                    <FormSpy onChange={onFormChange} subscription={{ values: true }} />
                  </form>
                );
              }}
            />
          </Box>
        </Box>

        {(isNewsLoading || isYhDataLoading) && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}

        {!isNewsLoading && !isYhDataLoading && (
          <Box>
            <MaterialTable
              columns={COLUMNS}
              data={news}
              onPageChange={onPageChange}
              totalCount={newsTotal}
              paginationEnabled
            />
            {newsModalOpen && (
              <NewsModal item={singleNews} isCreatingFromTemplate={templateModalOpen} handleClose={closeModals} />
            )}
            {templateModalOpen && (
              <NewsTemplateListModal onCloseClick={closeModals} onOpenTemplateClick={handleCreateFromTemplate} />
            )}
            {renderDeleteNewsModal()}
          </Box>
        )}
      </Box>
    </Layout>
  );
});

import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Typography as T, Box, Button } from "@material-ui/core";
import { Layout } from "../../components/Layout";
import MaterialTable from "../../components/MaterialTable/MaterialTable";
import { CommonModal } from "../../components/Modals/CommonModal";
import { useStores } from "../../stores";
import { ChecklistModal } from "./ChecklistModal";
import { ActionTableCell } from "../../components/ActionTableCell/ActionTableCell";
import { HtmlContentCell } from "../../components/HtmlContentCell/HtmlContentCell";
import { TranslationFlags } from "../../components/TranslationFlags/TranslationFlags";

export interface ChecklistScreenProps {}

export const ChecklistScreen: FC<ChecklistScreenProps> = observer((props: ChecklistScreenProps) => {
  const PAGE_SIZE = 10;
  const {
    checklistStore: {
      getChecklistItem,
      getChecklistItems,
      deleteChecklistItem,
      checklistItems,
      singleChecklistItem,
      checklistTotal,
      resetSingleChecklistItem,
    },
  } = useStores();

  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [deleteNewsModalOpen, setDeleteNewsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchFeedbacks = useCallback(
    async (pageIndex?: number) => {
      const page = pageIndex === undefined ? currentPage : pageIndex;
      const skip = PAGE_SIZE * page;
      return getChecklistItems({ take: PAGE_SIZE, skip });
    },
    [getChecklistItems, currentPage]
  );

  useEffect(() => {
    if (!initialized) {
      fetchFeedbacks();
      setInitialized(true);
    }
  }, [fetchFeedbacks, initialized]);

  const handleDeleteClick = useCallback(
    () => async (id: string) => {
      await getChecklistItem(id);
      setDeleteNewsModalOpen(true);
    },
    [getChecklistItem, setDeleteNewsModalOpen]
  );

  const handleEditClick = useCallback(
    () => async (id: string) => {
      await getChecklistItem(id);
      setDetailsOpen(true);
    },
    [getChecklistItem, setDetailsOpen]
  );

  const handleDeleteConfirm = async () => {
    if (singleChecklistItem?.id) {
      await deleteChecklistItem(singleChecklistItem.id);
    }
    setDeleteNewsModalOpen(false);
    fetchFeedbacks();
  };

  const handleModalClose = () => {
    setDetailsOpen(false);
    setDeleteNewsModalOpen(false);
  };

  const onPageChange = async (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
      fetchFeedbacks(pageIndex);
    }
  };

  const handleAddClick = () => {
    resetSingleChecklistItem();
    setDetailsOpen(true);
  };

  const addButton = (
    <Box>
      <Button variant="outlined" startIcon="+" onClick={handleAddClick}>
        {t("checklist.addNewChecklistItem")}
      </Button>
    </Box>
  );

  const COLUMNS = useMemo(() => {
    const cols = [
      {
        accessor: "title",
        Header: t("common.title"),
        width: "25%",
      },
      {
        accessor: "content",
        Header: t("common.content"),
        width: "75%",
        Cell: ({ row: { original } }: { row: { original: IChecklistItem } }) => {
          return <HtmlContentCell content={original.content} maxWidth={500} />;
        },
      },
      {
        accessor: "title_fi",
        Header: "",
        style: { maxWidth: 60 },
        Cell: ({ row: { original } }: { row: { original: IChecklistItem } }) => {
          const hasEnTranslation = !!original.title_en && !!original.content_en;
          const hasSvTranslation = !!original.title_sv && !!original.content_sv;

          return <TranslationFlags hasEnTranslation={hasEnTranslation} hasSvTranslation={hasSvTranslation} />;
        },
      },
      {
        accessor: "id",
        Header: "",
        Cell: ({ cell }: any) => (
          <ActionTableCell onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} params={cell.value} />
        ),
      },
    ];
    return cols;
  }, [handleEditClick, handleDeleteClick, t]);

  const renderDeleteNewsModal = () => (
    <CommonModal
      open={deleteNewsModalOpen}
      type="prompt"
      title={t("checklist.deleteChecklistItemConfirm")}
      confirmText={t("common.remove")}
      onConfirm={handleDeleteConfirm}
      onCancel={handleModalClose}
    >
      <T>{t("checklist.deleteChecklistItemConfirmBody")}</T>
    </CommonModal>
  );

  return (
    <Layout headerText={t("sidemenu.checklist")} headerElementsRight={addButton}>
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
          <T color="textSecondary">{t("checklist.count", { count: checklistTotal })}</T>
        </Box>
        <MaterialTable
          columns={COLUMNS}
          data={checklistItems}
          onPageChange={onPageChange}
          totalCount={checklistTotal}
          paginationEnabled
        />
      </Box>
      {detailsOpen && (
        <ChecklistModal isOpen={detailsOpen} item={singleChecklistItem} handleClose={() => setDetailsOpen(false)} />
      )}
      {renderDeleteNewsModal()}
    </Layout>
  );
});

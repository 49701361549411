import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Typography as T, Box, Button } from "@material-ui/core";
import { Layout } from "../../components/Layout";
import MaterialTable from "../../components/MaterialTable/MaterialTable";
import { CommonModal } from "../../components/Modals/CommonModal";
import { useStores } from "../../stores";
import { PageContentModal } from "./PageContentModal";
import { ActionTableCell } from "../../components/ActionTableCell/ActionTableCell";
import { HtmlContentCell } from "../../components/HtmlContentCell/HtmlContentCell";
import { TranslationFlags } from "../../components/TranslationFlags/TranslationFlags";

export interface PageContentScreenProps {}

export const PageContentScreen: FC<PageContentScreenProps> = observer(() => {
  const PAGE_SIZE = 10;
  const {
    pageContentStore: {
      getPageContent,
      getPageContents,
      deletePageContent,
      pageContents,
      pageContent,
      pageContentTotal,
      getPageSlugs,
      resetPageContent,
    },
  } = useStores();

  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [deletePageContentModalOpen, setDeletePageContentModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchPages = useCallback(
    async (pageIndex?: number) => {
      const page = pageIndex === undefined ? currentPage : pageIndex;
      const skip = PAGE_SIZE * page;
      return getPageContents({ take: PAGE_SIZE, skip });
    },
    [getPageContents, currentPage]
  );

  useEffect(() => {
    if (!initialized) {
      fetchPages();
      getPageSlugs();
      setInitialized(true);
    }
  }, [fetchPages, initialized, getPageSlugs]);

  const handleDeleteClick = useCallback(
    () => (id: string) => {
      getPageContent(id);
      setDeletePageContentModalOpen(true);
    },
    [getPageContent, setDeletePageContentModalOpen]
  );

  const handleEditClick = useCallback(
    () => async (id: string) => {
      await getPageContent(id);
      setDetailsOpen(true);
    },
    [getPageContent, setDetailsOpen]
  );

  const handleDeleteConfirm = async () => {
    if (pageContent?.id) {
      await deletePageContent(pageContent.id);
    }
    setDeletePageContentModalOpen(false);
    fetchPages();
  };

  const handleModalClose = () => {
    setDetailsOpen(false);
    setDeletePageContentModalOpen(false);
  };

  const onPageChange = async (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
      fetchPages(pageIndex);
    }
  };

  const handleAddClick = () => {
    resetPageContent();
    setDetailsOpen(true);
  };

  const headerButtons = (
    <Box display="flex">
      <Box mr={2}>
        <Button variant="outlined" startIcon="+" onClick={handleAddClick}>
          {t("pageContent.addNewPageContent")}
        </Button>
      </Box>
    </Box>
  );

  const COLUMNS = useMemo(() => {
    const cols = [
      {
        accessor: "slug",
        Header: t("common.slug"),
        width: "25%",
      },
      {
        accessor: "title",
        Header: t("common.title"),
        width: "25%",
      },
      {
        accessor: "content",
        Header: t("common.content"),
        width: "75%",
        Cell: ({ row: { original } }: { row: { original: IPageContent } }) => {
          return <HtmlContentCell content={original.content} maxWidth={500} />;
        },
      },
      {
        accessor: "title_fi",
        Header: "",
        Cell: ({ row: { original } }: { row: { original: IPageContent } }) => {
          const hasEnTranslation = !!original.title_en && !!original.content_en;
          const hasSvTranslation = !!original.title_sv && !!original.content_sv;

          return <TranslationFlags hasEnTranslation={hasEnTranslation} hasSvTranslation={hasSvTranslation} />;
        },
      },
      {
        accessor: "id",
        Header: "",
        Cell: ({ cell }: any) => (
          <ActionTableCell onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} params={cell.value} />
        ),
      },
    ];
    return cols;
  }, [handleEditClick, handleDeleteClick, t]);

  const renderDeleteNewsModal = () => (
    <CommonModal
      open={deletePageContentModalOpen}
      type="prompt"
      title={t("pageContent.deletePageContentConfirm")}
      confirmText={t("common.remove")}
      onConfirm={handleDeleteConfirm}
      onCancel={handleModalClose}
    >
      <T>{t("pageContent.deletePageContentConfirmBody")}</T>
    </CommonModal>
  );

  return (
    <Layout headerText={t("pageContent.pageContent")} headerElementsRight={headerButtons}>
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
          <T color="textSecondary">{t("pageContent.count", { count: pageContentTotal })}</T>
        </Box>
        <MaterialTable
          columns={COLUMNS}
          data={pageContents}
          onPageChange={onPageChange}
          totalCount={pageContentTotal}
          paginationEnabled
        />
      </Box>
      {detailsOpen && (
        <PageContentModal isOpen={detailsOpen} item={pageContent} handleClose={() => setDetailsOpen(false)} />
      )}
      {renderDeleteNewsModal()}
    </Layout>
  );
});

import { Box } from "@material-ui/core";
import React, { FC } from "react";

interface TranslationFlagsProps {
  hasEnTranslation: boolean;
  hasSvTranslation: boolean;
}

export const TranslationFlags: FC<TranslationFlagsProps> = ({ hasEnTranslation, hasSvTranslation }) => {
  const SIZE = 16;

  const inactiveStyles = {
    filter: "grayscale(100%)",
    opacity: 0.2,
  };

  return (
    <Box display="flex">
      <img
        src="/images/en.png"
        alt="En"
        height={SIZE}
        width={SIZE}
        style={!hasEnTranslation ? inactiveStyles : undefined}
      />
      <Box mr={1} />
      <img
        src="/images/sv.png"
        alt="Sv"
        height={SIZE}
        width={SIZE}
        style={!hasSvTranslation ? inactiveStyles : undefined}
      />
    </Box>
  );
};

import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Typography as T, Box, Button } from "@material-ui/core";
import { Layout } from "../../components/Layout";
import MaterialTable from "../../components/MaterialTable/MaterialTable";
import { CommonModal } from "../../components/Modals/CommonModal";
import { useStores } from "../../stores";
import { FaqModal } from "./FaqModal";
import { ActionTableCell } from "../../components/ActionTableCell/ActionTableCell";
import { HtmlContentCell } from "../../components/HtmlContentCell/HtmlContentCell";
import { TranslationFlags } from "../../components/TranslationFlags/TranslationFlags";

export interface FaqScreenProps {}

export const FaqScreen: FC<FaqScreenProps> = observer(() => {
  const PAGE_SIZE = 10;
  const {
    faqStore: { getFaqItem, getFaqItems, deleteFaqItem, faqItems, singleFaqItem, faqTotal, resetSingleFaqItem },
  } = useStores();

  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [deleteFaqItemModalOpen, setDeleteFaqItemModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchFaqs = useCallback(
    async (pageIndex?: number) => {
      const page = pageIndex === undefined ? currentPage : pageIndex;
      const skip = PAGE_SIZE * page;
      return getFaqItems({ take: PAGE_SIZE, skip });
    },
    [getFaqItems, currentPage]
  );

  useEffect(() => {
    if (!initialized) {
      fetchFaqs();
      setInitialized(true);
    }
  }, [fetchFaqs, initialized]);

  const handleDeleteClick = useCallback(
    () => (id: string) => {
      getFaqItem(id);
      setDeleteFaqItemModalOpen(true);
    },
    [getFaqItem, setDeleteFaqItemModalOpen]
  );

  const handleEditClick = useCallback(
    () => (id: string) => {
      getFaqItem(id);
      setDetailsOpen(true);
    },
    [getFaqItem, setDetailsOpen]
  );

  const handleDeleteConfirm = async () => {
    if (singleFaqItem?.id) {
      await deleteFaqItem(singleFaqItem.id);
    }
    setDeleteFaqItemModalOpen(false);
    fetchFaqs();
  };

  const handleModalClose = () => {
    setDetailsOpen(false);
    setDeleteFaqItemModalOpen(false);
  };

  const onPageChange = async (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
      fetchFaqs(pageIndex);
    }
  };

  const handleAddClick = () => {
    resetSingleFaqItem();
    setDetailsOpen(true);
  };

  const headerButtons = (
    <Button variant="outlined" startIcon="+" onClick={handleAddClick}>
      {t("faq.addNewFaqItem")}
    </Button>
  );

  const COLUMNS = useMemo(() => {
    const cols = [
      {
        accessor: "title",
        Header: t("common.title"),
        width: "25%",
      },
      {
        accessor: "content",
        Header: t("common.content"),
        width: "75%",
        Cell: ({ row: { original } }: { row: { original: IFaqItem } }) => {
          return <HtmlContentCell content={original.content} maxWidth={500} />;
        },
      },
      {
        accessor: "title_fi",
        Header: "",
        style: { maxWidth: 60 },
        Cell: ({ row: { original } }: { row: { original: IFaqItem } }) => {
          const hasEnTranslation = !!original.title_en && !!original.content_en;
          const hasSvTranslation = !!original.title_sv && !!original.content_sv;

          return <TranslationFlags hasEnTranslation={hasEnTranslation} hasSvTranslation={hasSvTranslation} />;
        },
      },
      
      {
        accessor: "id",
        Header: "",
        Cell: ({ cell }: any) => (
          <ActionTableCell onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} params={cell.value} />
        ),
      },
    ];
    return cols;
  }, [handleEditClick, handleDeleteClick, t]);

  const renderDeleteNewsModal = () => (
    <CommonModal
      open={deleteFaqItemModalOpen}
      type="prompt"
      title={t("faq.deleteFaqItemConfirm")}
      confirmText={t("common.remove")}
      onConfirm={handleDeleteConfirm}
      onCancel={handleModalClose}
    >
      <T>{t("faq.deleteFaqItemConfirmBody")}</T>
    </CommonModal>
  );

  return (
    <Layout headerText={t("faq.faq")} headerElementsRight={headerButtons}>
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
          <T color="textSecondary">{t("faq.count", { count: faqTotal })}</T>
        </Box>
        <MaterialTable
          columns={COLUMNS}
          data={faqItems}
          onPageChange={onPageChange}
          totalCount={faqTotal}
          paginationEnabled
        />
      </Box>
      {detailsOpen && <FaqModal isOpen={detailsOpen} item={singleFaqItem} handleClose={() => setDetailsOpen(false)} />}
      {renderDeleteNewsModal()}
    </Layout>
  );
});
